@import "../../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.start-session,
.pause-session,
.resume-session {
  padding-right: 16px !important;

  &:before {
    content: '';
    background: url("../../../assets/icons/session.svg") center no-repeat;
    display: inline-block;
    width: 18px;
    height: 23px;
    margin-right: 11px;
    margin-left: -11px;
    vertical-align: text-top;
  }
}

.pause-session {
  &:before {
    background: url("../../../assets/icons/pause.svg") center no-repeat;
  }
}

.resume-session {
  &:before {
    background: url("../../../assets/icons/play.svg") center no-repeat;
  }
}

.connected-viewers {
  .table {
    td {
      &.name {
        font-weight: $font-weight-bold;
      }

      &.role {
        > * {
          text-transform: capitalize;
          color: #1A8E80;
          background: #CCFCF0;
          border-radius: 5px;
          display: inline-block;
          line-height: 26px;
          padding: 0 12px;
        }

        .contributor {
          background: #FEF2C7;
          color: #C07D1F;
        }

        .admin {
          background: #DBEAFE;
          color: #005DFF;
        }
      }
    }
  }
}