@import "../../styles/variables";
//@import "~bootstrap/scss/mixins/breakpoints";

button.upload {
  padding-right: 16px !important;

  &:before {
    content: '';
    background: url("../../assets/icons/upload.svg") center no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 11px;
    margin-left: -11px;
    vertical-align: text-top;
  }
}

.library {
  .filters-toolbar {
    margin-bottom: 50px;
  }
}

.library-list-item {
  display: flex;
  align-items: center;
  gap: 22px;
  background: #fff;
  padding-right: 20px;
  margin-bottom: 10px;
  transition: background .4s;

  > * {
    flex-shrink: 0;
  }

  .item-info {
    min-width: 0;
    flex-shrink: 1;
  }

  .title {
    margin-bottom: 0;
  }

  small {
    font-size: 20px;
    color: #898D98;
    display: block;
    margin-bottom: 6px;
  }

  .btn-add {
    border: none!important;
    box-shadow: none!important;
    color: #3a414e!important;
    background: none!important;
    font-size: 15px;
    font-weight: $font-weight-normal;
    padding: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity .4s;

    span:before {
      content: '';
      display: block;
      width: 43px;
      height: 29px;
      margin: auto;
      background: url("../../assets/icons/add-to-class.svg") no-repeat center;
    }
  }

  &:hover {
    background: #F2F4F8;

    .btn-add {
      opacity: 1;
      width: auto;
      height: auto;
    }
  }
}
