.login {
  height: 100vh;
  max-width: 538px;
  margin: auto;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    color: #00247d;
  }

  .form-control {
    line-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
  }

  .form-label {
    font-size: 22px;
    margin-bottom: 0.3rem;
  }

  .btn-primary {
    font-weight: bold;
    border-radius: 30px;
    padding: 14px 34px;
    background-color: #00247d;
    border-color: #00247d;
  }

  .title {
    font-size: 60px;
    letter-spacing: -2px;
  }

  .form-inputs {
    max-width: 340px;
    margin: auto;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .by {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    img {
      margin-left: 15px;
    }
  }
}