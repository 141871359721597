.default-layout {
  .page-body {
    padding-left: $container-padding-x;
    padding-right: $container-padding-x;
    padding-bottom: 27px;
  }

  @include media-breakpoint-up($mobile-breakpoint) {
    display: flex;

    .navbar {
      flex: 0 0 auto;
      min-height: 100vh;
      width: $navbar-sidebar-width;
      flex-direction: column;
    }

    .page-body {
      flex: 1 1 auto;
      padding-top: 70px;
      padding-left: 52px;
      max-width: 1298px;
    }
  }
}