@import "../../styles/variables";
//@import "~bootstrap/scss/mixins/breakpoints";


.thumb-modal {
  width: 150px;
  height: 95px;
  overflow: hidden;
  display: flex;
  position: relative;
  background: $primary;

  .play {
    background: url("../../assets/icons/play.svg") no-repeat;
    background-size: contain;
    width: 17px;
    height: 19px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -6px;
    margin-top: -9px;
    opacity: .6;
    font-size: 0;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .duration {
    position: absolute;
    right: 7px;
    bottom: 5px;
    background-color: #000;
    font-size: 12px;
    color: #fff;
    line-height: 19px;
    padding: 0 6px;
  }
}

.modal {
  .video-modal {
    width: 1268px;
    max-width: 90vw;
  }
}

.modal-backdrop.show.video-backdrop {
  opacity: .7;

  &:after {
    content: '';
    background: url("../../assets/icons/close-thin.svg") no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 65px;
    margin-top: 45px;
  }
}
