body {
  min-width: 360px;
  letter-spacing: -0.6px;
}

button {
  letter-spacing: -0.6px;
}

h1 {
  letter-spacing: -1.2px;
}

h5 {
  letter-spacing: -0.75px;
}

.sync-code {
  margin-left: auto;
}

.toast-container {
  position: fixed;
  top: 35px;
  right: 35px;
}
