@import "../../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.navbar {
  .nav-link {
    padding: 12px 0;
  }

  &.navbar-light .navbar-nav {
    > .nav-link,
    .dropdown > .nav-link {
      color: $black;
      font-weight: $font-weight-bold;
      font-size: 20px;
    }

    > .nav-link {
      margin-left: 20px;

      &.active,
      &:hover {
        color: $navbar-light-active-color;
      }
    }

    /*.dropdown > .nav-link {
      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 9px;
        background: url("../../../assets/icons/dropdown.svg") 0 -2px no-repeat;
      }

      &:after {
        content: none;
      }
    }*/
  }

  .dropdown-menu {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
  }

  /*.dropdown-item {
    background: none !important;
    color: $navbar-light-color;
    padding: 7px 0 7px 42px;
    font-size: 20px;

    &.active {
      color: $navbar-light-active-color;
      font-weight: $font-weight-bold;
    }
  }*/

  @include media-breakpoint-up($mobile-breakpoint) {
    flex-direction: column;
    padding-top: 58px;
    padding-left: 48px;

    .navbar-collapse {
      flex-grow: 0;
      flex-direction: column;
      width: 100%;

      .navbar-nav {
        flex-direction: column;

        .nav-item {
          .dropdown-menu {
            position: static;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up($mobile-breakpoint) {
  .navbar-brand {
    margin-bottom: 45px;
  }
}