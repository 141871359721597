@import "../../styles/variables";
//@import "~bootstrap/scss/mixins/breakpoints";

.classroom-add {
  padding-right: 16px !important;

  &:before {
    content: '';
    background: url("../../assets/icons/plus.svg") center no-repeat;
    display: inline-block;
    width: 16px;
    height: 23px;
    margin-right: 11px;
    margin-left: -11px;
    vertical-align: text-top;
  }
}

.classroom-listing {
  .users {
    .in,
    img,
    .count {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #F2F4F8;
      border: 2px solid #fff;
      border-radius: 15px;
      font-size: 12px;
      letter-spacing: -0.36px;
      line-height: 26px;
      text-align: center;
      overflow: hidden;
      vertical-align: top;
    }

    .count:before {
      content: '+';
    }

    > * + * {
      margin-left: -9px;
    }

    .in {
      background-color: $primary;
      color: $white;
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
