.dropdown.single-action {
  .dropdown-toggle {
    background: url("../../assets/icons/actions.svg") center no-repeat !important;
    box-shadow: none !important;
    width: 34px;
    height: 18px;
    font-size: 0;
    padding: 0;
    border: none;
  }
}