.page-title {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 14px;

  .btn-secondary {
    font-size: 30px;
    line-height: 48px;
    width: 50px;
    padding: 0;
    font-weight: normal;
  }

  button {
    font-size: 20px;
    margin-left: 14px;
  }

  h1 {
    margin: 0;
  }

  @include media-breakpoint-up($mobile-breakpoint) {
    margin-bottom: 55px;
  }
}