@import "../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$table-th-padding-x: .278rem !default;

.table {
  font-size: 20px;

  > :not(caption) > * > th {
    padding-top: $table-th-padding-x;
    padding-bottom: $table-th-padding-x;
    vertical-align: middle;
  }

  > tbody > tr:first-child > * {
    padding-top: 1.1115rem;
  }

  > thead > tr > * {
    //--#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    background-color: $light;
    //color: $black;
  }

  .avatar {
    position: relative;
    height: 30px;
    width: 30px;

    .user {
      border-radius: 15px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 15px;
      }

      .in {
        background-color: $primary;
        color: $white;
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
      }
    }

    &.online:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 2px solid $white;
      background: #4BDF7F;
      position: absolute;
      right: -3px;
      bottom: -3px;
    }
  }
}

.table > thead > tr > *
//.table-striped > tbody > tr:nth-of-type(#{$table-striped-order}) > *
{
  &:first-child {
    border-radius: 7px 0 0 7px;
  }

  &:last-child {
    border-radius: 0 7px 7px 0;
  }
}
