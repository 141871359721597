@import "../../../styles/variables";
//@import "~bootstrap/scss/mixins/breakpoints";

.lessons-list-draggable {
  margin-bottom: 25px;
}

.lessons-list-draggable-item {
  display: flex;
  align-items: center;
  gap: 22px;
  background: #fff;
  padding: 0 20px 0 0;
  margin-bottom: 10px;
  transition: background .4s;

  > * {
    flex-shrink: 0;
  }

  .drag-handle {
    display: block;
    width: 26px;
    height: 24px;
    margin-left: 22px;
    background: url("../../../assets/icons/drag-handle.svg") no-repeat;
    transition: background .4s;
  }

  .item-info {
    min-width: 0;
    flex-shrink: 1;
  }

  .title {
    margin-bottom: 0;
  }

  small {
    font-size: 20px;
    color: #898D98;
    display: block;

    /*span + span {
      &:before {
        content: '•';
        display: inline-block;
        margin: 0 9px;
      }
    }*/
  }

  .btn-delete {
    background: url("../../../assets/icons/close-white.svg") no-repeat center;
    background-color: #3A414E!important;
    border: none;
    height: 35px;
    font-size: 0;
    padding: 0;
    transition: opacity .4s;
    opacity: 0;
    width: 0;
  }

  &:hover {
    background: #E3E5EE;

    .drag-handle {
      background: url("../../../assets/icons/drag-handle-dark.svg") no-repeat;
    }

    .btn-delete {
      opacity: 1;
      width: 35px;
    }
  }

  &.active {
    background: #f1f2f8;
  }
}