@import "../../../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.classroom-viewer {
  margin-bottom: 65px;

  .video-container {
    min-height: 390px;
    background-color: $black;
    display: flex;
    position: relative;
    align-items: center;

    //.start-btn {
    //  min-width: 236px;
    //  font-size: 20px;
    //
    //  &:before {
    //    content: '';
    //    background: url("../../../../assets/icons/play.svg") no-repeat;
    //    background-size: contain;
    //    display: inline-block;
    //    width: 12px;
    //    height: 14px;
    //    margin-right: 15px;
    //  }
    //}

    .controls {
      //position: absolute;
      //left: 0;
      //right: 0;
      //top: 0;
      //bottom: 0;
      //display: flex;
      //flex-direction: column;
      //justify-content: space-between;

      //.play {
      //  display: flex;
      //  align-items: center;
      //  justify-content: center;
      //  flex-grow: 1;
      //}

      /*.progress {
        height: 3px;
        background: none;
        margin: 0 60px 35px;
        position: relative;
        border-radius: 3px;
        overflow: visible;

        &:after {
          content: '';
          display: block;
          height: 1px;
          background-color: #f2f4f870;
          position: absolute;
          left: 0;
          right: 0;
          top: 1px;
        }

        .progress-bar {
          height: 3px;
          background-color: #fff;
          position: relative;
          overflow: visible;
          border-radius: 2px;

          &:after {
            content: '';
            display: block;
            height: 15px;
            width: 15px;
            border-radius: 8px;
            background-color: #fff;
            position: absolute;
            right: -6px;
            top: -6px;
          }
        }
      }*/
    }

    .vjs-big-play-button {
      display: none;
    }

    .vjs-control-bar {
      .vjs-play-control,
      .vjs-fullscreen-control {
        display: none;
      }
    }
  }

  .playlist {
    flex-grow: 1;
  }

  @media (min-width: 1830px) {
    width: 1472px;
  }

  @include media-breakpoint-up($mobile-breakpoint) {
    display: flex;
    max-height: 390px;
    overflow: hidden;

    .video-container {
      flex-basis: 53%;
      flex-shrink: 0;
    }

    .playlist {
      min-width: 450px;
      flex-shrink: 1;
      overflow: auto;

      .add-to-playlist {
        font-size: 20px;
      }
    }
  }
}

.video-js {
  transition: padding-top .2s;

  &:before {
    content: '';
    background: url("../../../../assets/images/metaenga-logo-white.svg") no-repeat;
    background-size: contain;
    width: 15%;
    height: 25px;
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 1;
  }

  .vjs-big-play-button {
    background-color: transparent !important;
    border: 0;
    font-size: 6em;
  }

  .vjs-control-bar {
    display: flex;
    opacity: 1 !important;
    background-color: transparent;
    flex-wrap: wrap;
    height: 75px;
    justify-content: space-between;
    padding: 0 25px;

    > * {
      height: 45px;
    }

    .vjs-progress-control {
      flex-basis: 100%;
      order: -1;
      height: 30px;
    }

    .vjs-picture-in-picture-control {
      display: none;
    }
  }

  .vjs-play-progress {
    background-color: #FF0000;

    &:before {
      top: -2px;
      font-size: 9px;
    }
  }

  .vjs-load-progress div {
    background: rgb(232, 234, 241);
  }

  .vjs-volume-level:before {
    top: -2px;
    font-size: 8px;
  }

  .vjs-progress-holder {
    height: 5px;
  }

  .vjs-slider {
    background-color: $white;

    &:focus {
      text-shadow: none;
      box-shadow: none;
    }
  }

  .vjs-time-tooltip {
    padding: 6px 5px 5px;
  }

  .vjs-time-control {
    font-size: 14px;
    line-height: 32px;
    padding-right: 11px;
  }

  .vjs-volume-panel,
  .vjs-play-control {
    .vjs-icon-placeholder:before {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .vjs-fullscreen-control {
    display: none;
    //.vjs-icon-placeholder:before {
    //  line-height: 32px;
    //}
    //
    //&.vjs-button > .vjs-icon-placeholder:before {
    //  text-align: right;
    //  padding-right: 7px;
    //}
  }

  .vjs-volume-panel {
    margin-right: auto;
  }
}

.vjs-button > .vjs-icon-placeholder:before {
  text-align: left;
}

.vjs-mute-control {
  &.vjs-button > .vjs-icon-placeholder:before {
    padding-left: 7px;
  }
}