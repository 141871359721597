.modal-header {
  justify-content: flex-start;
  gap: 20px;

  .btn-close {
    order: -1;
    margin: 0;
    padding: 0;
    background: $secondary;
    font-size: 30px;
    line-height: 48px;
    height: 50px;
    width: 50px;
    opacity: 1;

    &:before {
      content: '<';
    }
  }
}

.modal-title {
  font-size: $h1-font-size;
  letter-spacing: -1.2px;
}

.modal-body {
  font-size: 20px;
  padding-bottom: 1rem;

  .form-label {
    font-size: 25px;
    letter-spacing: -0.75px;
    margin-bottom: 20px;
  }

  .form-check-label {
    font-size: 25px;
    letter-spacing: -0.75px;
    font-weight: $font-weight-bold;
    color: $black;
    line-height: 31px;
  }

  .col-form-label {
    margin-bottom: 0;
  }

  .form-control {
    font-size: 20px;
    padding: 0.5rem 1.4rem;
  }

  .form-select {
    font-size: 20px;
    padding: 0.5rem 2.25rem 0.5rem 1.4rem;
  }
}

.modal-footer {
  .btn-primary {
    min-width: 155px;
  }
}

@include media-breakpoint-up($mobile-breakpoint) {
  .modal-lg {
    .modal-content {
      padding: 12px;
    }
  }
}