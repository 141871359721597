@import "../../styles/variables";
//@import "~bootstrap/scss/mixins/breakpoints";

.filters-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;

  .add-filter {
    color: #000;
    background-color: #F2F4F8;
    border: none;
    font-size: 20px;
    padding: 5px 10px 5px 15px;

    &:hover {
      color: #000;
      background-color: #c6c8c9;
    }

    &:after {
      content: '';
      background: url("../../assets/icons/plus-grey.svg") center no-repeat;
      background-size: contain;
      display: inline-block;
      width: 14px;
      height: 18px;
      margin-left: 6px;
      vertical-align: top;
      margin-top: 6px;
    }
  }

  .filter-current {
    border: 1px solid #E3E5EE;
    border-radius: 7px;
    background-color: $white;
    padding: 0 15px;
    line-height: 38px;

    .filter-label {
      margin-right: 10px;
    }

    .filter-value {
      color: $black;
      font-weight: $font-weight-bold;
    }

    .remove {
      background: url("../../assets/icons/close.svg") center no-repeat;
      font-size: 0;
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: top;
      margin-top: 12px;
      margin-left: 10px;
    }
  }

  .dropdown {

  }
}

.search-input {
  flex-basis: 500px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 22px;
    height: 20px;
    background: url("../../assets/icons/search.svg") no-repeat;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  input {
    padding-left: 35px;
  }
}